import axios from "axios";
// const backend = "http://localhost:4000"
const backend = "https://hanis-backend-git-main-numans-projects-caa969b9.vercel.app"
const FormData = require("form-data");
const { ApiCall } = require("../Services/ApiCall");
// this is for create the product
const add_new_product = async (body) => {
  console.log("body seding", body);
  console.log(body.images[0])
  let data = new FormData();
  data.append("name", body.name);
  data.append("price", body.price);
  data.append("discount", body.discount);
  data.append("quantity", body.quantity);
  data.append("description", body.description);
  data.append("heat", body.heat);
  data.append("usage", JSON.stringify(body.usage));
  data.append("ingredients", JSON.stringify(body.ingredients));
  data.append("nutrition", JSON.stringify(body.nutrition));
  data.append("category", body.category);
  body.images.forEach((image, index) => {
    data.append(`images`, image); // 'images' is the key used for all files
  });

  //   const res = await ApiCall({
  //     route: "product/create",
  //     method: "post-form",
  //     body: data,
  //     token :"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoiNjY4YTc3MzNmY2EzMzY1MDg4OTI3ODdjIiwiaWF0IjoxNzIwNjA5MTgxLCJleHAiOjE3MjA2MTI3ODF9.FQKhPrN5cLYADSazF4biXH3DdegjYvpmU0J68NrED3Q"
  //   });
  console.log("body seding by form", data);
  const res = await axios.post(`${backend}/product/create`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authentication:
        // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoiNjZiN2E3ZTFjMjM0MTk2OTkwYjcwMWY4IiwiaWF0IjoxNzI0NTgwODQ5fQ.azSEelWiRdnNMcFZEc5_unbygogj1r-93ictIGp1u0A",
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoiNjZjMjYyMjRiM2IyMjI2ZGJlMWYwNTUyIiwiaWF0IjoxNzI0MDE1MjA2fQ.FE_Au_tlNGTSpWK1rW7v5oibezpyZPvDuXgPF4cVv4Y"
    },
  });
  console.log("resss", res.data);
  return res?.data;
};


//this for get the all contact request form data

const get_all_contact_request = async () => {

  const respone = await axios.get(`${backend}/user/form/all`);
  return respone?.data
}


// this api is used for product sub category creation
const createSubCategory = async (body) => {

  if (!body) return " Body is empty"
  const response = await axios.post(`${backend}/product/sub/category/create`, body)

  return response?.data
}

//this for get the all sub categories of the product
const get_all_sub_categories = async () => {

  const respone = await axios.get(`${backend}/product/sub/category/all`);
  return respone?.data
}

//this is used for getting all the list of subscribers
const get_all_subscribers = async () => {

  const respone = await axios.get(`${backend}/user/subscribe/all`);
  return respone?.data
}


//this is for getting all the orders details
const get_all_orders = async (query) => {

  const respone = await axios.get(`${backend}/orders/all?query=${query}`);
  return respone?.data
}

//this is for get products detail
const get_products = async (query) => {

  const respone = await axios.get(`${backend}/product/all?select=${query}`);
  return respone?.data
}

const get_analytics = async () => {

  const respone = await axios.get(`${backend}/orders/analytics`);
  return respone?.data
}


export {
  add_new_product,
  get_all_contact_request,
  createSubCategory,
  get_all_sub_categories,
  get_all_subscribers,
  get_all_orders,
  get_products,
  get_analytics,
  backend
};
