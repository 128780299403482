import React, { useState, useEffect } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import "./Dashboard.scss"
import Home from "../Home/Home"
import AllProducts from '../Products/AllProducts';
import AddProducts from '../Products/AddProducts';
import Request from '../Request';
import AddCategories from '../Products/AddCategories';
import AllCategories from '../Products/AllCategores';
import AllSubscribers from '../Subscribe';
import AllOrders from '../Orders/AllOrders';
import SingleOrder from '../Orders/SingleOrder';
function Dashboard() {
    const [view, setView] = useState("Home");
    console.log(view)
    const showView = (show) => {
        switch (show) {
            case "Home":
                return <Home />
            case "All_Products":
                return <AllProducts />
            case "Add_Products":
                return <AddProducts />
            case "Request":
                return <Request />
            case "Add_Categories":
                return <AddCategories />
            case "All_Categories":
                return <AllCategories />
            case "All_Subscribe":
                return <AllSubscribers />
            case "All_Orders":
                return <AllOrders changeView={changeView} />
            case "Single_Order":
                return <SingleOrder />
            default:
                return <Home />
        }
    }

    const changeView = (name) => {
        setView(name)
    }


    // useEffect(() => {
    //     // changeView()/
    // }, [view])


    return (
        <div className="row mt-3" >
            <div className="col-2 mynav">
                <Navbar view={view} change={changeView} />
            </div>
            <div className="col-10">
                {showView(view)}
            </div>
        </div>
    )
}

export default Dashboard
