import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { get_all_orders } from "../../Configuration/Api";
import Swal from "sweetalert2";
import OrderDetails from "../../Components/OrderView";

function SingleOrder() {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    console.log("IDd", id)
    const [data, setData] = useState([]);
    const getData = async () => {
        const res = await get_all_orders(id);
        console.log("single order",res)
        if (res?.success) {
            setData(res?.data[0])
        } else {
            Swal.fire({
                icon: "error",
                title: "Something went wrong"
            })
        }
    }


    useEffect(() => {
        getData()
    }, [])

  
    return (
        <div>
            <OrderDetails order={data} />
        </div>
    )
}

export default SingleOrder