import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { backend, get_products } from "../../Configuration/Api";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Add axios for API calls

function AllProducts({ changeView }) {

    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [productIdToDelete, setProductIdToDelete] = useState(null);
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [discount, setDiscount] = useState('');

    const navigate = useNavigate();
    const getData = async () => {
        const res = await get_products("all");
        if (res?.success) {
            setData(res?.data)
        } else {
            Swal.fire({
                icon: "error",
                title: "Something went wrong"
            })
        }
    }

    useEffect(() => {
        getData()
    }, [])

    const goToSingleOrder = (id) => {
        navigate(`?id=${id}`)
        changeView("Single_Order")
    }

    const openUpdateModal = (product) => {
        setSelectedProduct(product);
        setName(product.name);
        setPrice(product.price);
        setDescription(product.description);
        setDiscount(product.discount);
        setIsModalOpen(true);
    }

    const handleUpdate = async () => {
        try {
            const updateData = { name, price, description, discount };
            const res = await axios.put(`${backend}/product/update/${selectedProduct._id}`, updateData);
            if (res.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Product updated successfully"
                });
                getData(); // Refresh the data
                setIsModalOpen(false);
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error updating product",
                text: error.message
            });
        }
    }

    const openDeleteModal = (id) => {
        setProductIdToDelete(id);
        setIsDeleteModalOpen(true);
    }

    const handleDelete = async () => {
        try {
            const res = await axios.delete(`${backend}/product/delete/${productIdToDelete}`);
            if (res.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Product deleted successfully"
                });
                getData(); // Refresh the data
                setIsDeleteModalOpen(false);
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error deleting product",
                text: error.message
            });
        }
    }

    return (
        <div>
            <h3 style={{
                backgroundColor: "tomato",
                color: "white",
                textAlign: "center",
                padding: "5px",
                borderRadius: "10px"
            }}>All Products</h3>
            <table border="" cellPadding="10" cellSpacing="0" style={{ width: '100%', textAlign: 'left' }}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Product ID</th>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Category</th>
                        <th>Discount</th>
                        <th>Status</th>
                        <th>CreatedBy</th>
                        <th>View</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index} className="border"
                        >
                            <td>
                                <img src={item?.images?.[0]?.Location} alt={"sa"} style={{ width: '150px', height: "150px", marginRight: '5px' }} />
                            </td>
                            <td>{item._id.toString().slice(-5)}</td>
                            <td>{item?.name}</td>
                            <td>{item?.price}</td>
                            <td>{item?.category}</td>
                            <td>{item?.discount}%</td>
                            <td>{item.status}</td>
                            <td >{item?.createdBy?.first_name}</td>
                            <td >
                                <p
                                    onClick={() => openUpdateModal(item)}
                                    className="p-2 text-center text-white cursor-pointer mt-2" style={{ backgroundColor: "green", cursor: "pointer" }} >
                                    Update
                                </p>
                            </td>
                            <td >
                                <p
                                    onClick={() => openDeleteModal(item._id)}
                                    className="p-2 text-center text-white cursor-pointer mt-2" style={{ backgroundColor: "red", cursor: "pointer" }} >
                                    delete
                                </p>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h4>Update Product</h4>
                        <label>
                            Name:
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </label>
                        <label>
                            Price:
                            <input
                                type="text"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </label>
                        <label>
                            Description:
                            <input
                                type="text"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </label>
                        <label>
                            Discount:
                            <input
                                type="text"
                                value={discount}
                                onChange={(e) => setDiscount(e.target.value)}
                            />
                        </label>
                        <button onClick={handleUpdate}>Update</button>
                        <button onClick={() => setIsModalOpen(false)}>Cancel</button>
                    </div>
                </div>
            )}

            {isDeleteModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h4>Confirm Delete</h4>
                        <p>Are you sure you want to delete this product?</p>
                        <button onClick={handleDelete}>Yes</button>
                        <button onClick={() => setIsDeleteModalOpen(false)}>No</button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AllProducts