import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { get_all_sub_categories } from "../../Configuration/Api";



function AllCategories() {

    const [data, setData] = useState([]);

    const getData = async () => {
       try {
        const res = await get_all_sub_categories();
        if (res?.success) {
            setData(res?.data)
        } else {
            Swal.fire({
                icon: "error",
                title: "Something went wrong"
            })
        }
       } catch (error) {
        Swal.fire({
            icon: "error",
            title: "Something went wrong"
        })
       }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div>
            <h3 style={{
                backgroundColor:"green",
                color: "white",
                textAlign: "center",
                padding: "5px",
                borderRadius: "10px"
            }}>All Sub Categories </h3>
            <table border="" cellPadding="10" cellSpacing="0" style={{ width: '100%', textAlign: 'left' }}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Created At</th>
                        <th className="text-center">Sub Category Name</th>
                        
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index} className="border">
                            <td>{index + 1}</td>
                            <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                            <td>{item.subject}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.phone}</td>
                            <td>{item.city}</td>
                            <td>{item.status}</td>
                            <td>{item.request}</td>
                            <td>{item.message}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default AllCategories