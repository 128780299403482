import React, { useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Header from '../Components/Header/Header';
import HomeRoute from './HomeRoute';
import Login from '../Components/Login';

function MainRoutes() {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [isAuthenticated, setIsAuthenticated] = useState(!!token);

    const handleLogin = () => {
        console.log('Login success');
        setIsAuthenticated(true);
        navigate('/'); // Navigate to home route after login
    };

    return (
        <div className="container">
            <Header />
            <Routes>
                <Route path="/login" element={<Login onLogin={handleLogin} />} />
                <Route
                    path="/"
                    element={
                        isAuthenticated ? <HomeRoute /> : <Navigate to="/login" />
                    }
                />
            </Routes>
        </div>
    );
}

export default function App() {
    return (
        <BrowserRouter>
            <MainRoutes />
        </BrowserRouter>
    );
}
