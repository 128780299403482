import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import "./Product.scss"
import 'bootstrap-icons/font/bootstrap-icons.css';
import Swal from 'sweetalert2';
import ApiCall from '../../Services/ApiCall';
import { add_new_product, get_all_sub_categories } from '../../Configuration/Api';
function AddProducts() {
    const { register, handleSubmit } = useForm();
    const [ingredients, setIngredients] = useState([])
    const [nutrition, setNutrition] = useState([])
    const [images, setImages] = useState([])
    // for adding the ingredients into the array

    const handleImages = (e) => {
        setImages([...e.target.files]);
    };
    const Added_Ingredients = async () => {
        const { value } = await Swal.fire({

            title: "Add New Ingredient",
            inputPlaceholder: "Eg:  Stabilizer (E415, E466)",
            input: "text",
            showCancelButton: true,
            confirmButtonText: "Add",
            inputValidator: (value) => {
                if (!value) {
                    return "You need to write something!";
                }
            }
        })
        if (value) {
            setIngredients([...ingredients, value])
            Swal.fire({
                icon: "success",
                title: "Ingredient Added Successfully!",
                timer: 3000
            })
        }
    }

    const Remove_Ingredients = async (name) => {
        Swal.fire({
            icon: "warning",
            title: "You want to delete this ? ",
            text: name,
            showCancelButton: true,

        }).then((res) => {
            if (res.isConfirmed) {
                setIngredients(() => ingredients.filter((item) => item != name));
            }
        })
    }

    // for adding the nutrition into the array
    const Added_Nutrition = async () => {
        const { value: formValues } = await Swal.fire({
            title: "Add Nutrition",
            showCancelButton: true,
            html: `
              <label>Name </label>
              <input id="swal-input1" class="swal2-input">
              <br/>
              <label>Value</label>
              <input id="swal-input2" class="swal2-input">
            `,
            focusConfirm: false,
            preConfirm: () => {
                const name = document.getElementById("swal-input1").value;
                const value = document.getElementById("swal-input2").value;

                if (!name || !value) {
                    Swal.showValidationMessage('Both fields are required!');
                    return false;
                }

                // Add any additional validation logic here if needed
                return { name, value };
            },
        });

        if (formValues) {
            setNutrition([...nutrition, { name: formValues.name, value: formValues.value }])
            Swal.fire({
                icon: "success",
                title: "Nutrition Added Successfully!",
                timer: 3000
            })
        }
    }


    const Remove_Nutrition = async (item, index) => {
        console.log(index)
        Swal.fire({
            icon: "warning",
            title: "You want to delete this ? ",
            text: `${item.name}/${item.value}`,
            showCancelButton: true,

        }).then((res) => {
            if (res.isConfirmed) {
                const res = nutrition.splice(index, 1)
                console.log(res);
                setNutrition(res);
            }
        })
    }

    // here we are submitting the forms
    const onSubmit = async (data) => {
        data.ingredients = ingredients;
        data.nutrition = nutrition;
        data.images = images;
        console.log("images", images)
        if (!data) {
            Swal.fire({
                icon: "error",
                title: "Please fill the form first!"
            })
        }
        // call the api of creating the product
        try {
            const response = await add_new_product(data)
            console.log("response", response);
            if (response?.success) {
                Swal.fire({
                    icon: "success",
                    title: "Product Added successfully!"
                }).then((ok) => {
                    if (ok) {
                        window.location.reload()
                    }
                })
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Something went wrong!",
                    text: "Product can not be created or please try again later"
                })
            }

        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Something went wrong!",
                text: error
            })
        }
    }

    const [subCategories, setAllSubCategories] = useState([]);

    const getAllSubCategories = async () => {
        const res = await get_all_sub_categories();
        setAllSubCategories(res?.data)
    }
    useEffect(() => {
        getAllSubCategories()
    }, [])


    return (
        <div>
            <h3>Added New Product</h3>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <label className="label">Name</label>
                    <input className="input" type="text" {...register("name", { required: true })} required placeholder="Garlic Ketchup" />
                </div>
                <div>
                    <label className="label">Price</label>
                    <input className="input" type="number" {...register("price", { required: true })} required placeholder="1299" />
                </div>
                <div>
                    <label className="label" >Qunatity</label>
                    <input type="number" className="input" {...register("quantity", { required: true })} required placeholder="100" />
                </div>
                <div>
                    <label className="label">Discount</label>
                    <input className="input" type={"number"} {...register("discount")} placeholder="5%" />
                </div>
                <div>
                    <label className="label">Description</label>
                    <textarea {...register("description")} placeholder="Our hot selling products" />
                </div>
                <div>
                    <label className="label">Usage</label>
                    <div className="usage">
                        <input type="checkbox" {...register("usage.dip")} />
                        <p>Dip</p>
                    </div>
                    <div className="usage">
                        <input type="checkbox" {...register("usage.sauce")} />
                        <p>Sauce</p>
                    </div>
                    <div className="usage">
                        <input type="checkbox" {...register("usage.cooking")} />
                        <p>Marinade/Cooking</p>
                    </div>
                </div>

                <div>
                    <label className="label">Heat Level</label>
                    <div className="usage">
                        <input type="radio" name="heat" value="1" {...register("heat")} />
                        <img src="https://www.dipitt.com/wp-content/uploads/2020/07/heat-level-red-web.png" width="20px" />
                    </div>
                    <div className="usage">
                        <input type="radio" name="heat" value="2" {...register("heat")} />
                        <img src="https://www.dipitt.com/wp-content/uploads/2020/07/heat-level-red-web.png" width="20px" />
                        <img src="https://www.dipitt.com/wp-content/uploads/2020/07/heat-level-red-web.png" width="20px" />

                    </div>
                    <div className="usage">
                        <input type="radio" name="heat" value="3" {...register("heat")} />
                        <img src="https://www.dipitt.com/wp-content/uploads/2020/07/heat-level-red-web.png" width="20px" />
                        <img src="https://www.dipitt.com/wp-content/uploads/2020/07/heat-level-red-web.png" width="20px" />
                        <img src="https://www.dipitt.com/wp-content/uploads/2020/07/heat-level-red-web.png" width="20px" />

                    </div>
                </div>

                <div>
                    <label className="label">Sub Category </label>
                    <select {...register("category")} className="w-50">
                        {subCategories.map((item) => (
                            <option key={item._id} value={item.name} className="p-3">
                                {item.name}
                            </option>
                        ))}
                    </select>

                </div>
                <div>
                    <label className="label">Ingredients</label>
                    {
                        ingredients?.map((item) => (
                            <p className="ingrendients" onClick={() => Remove_Ingredients(item)} >{item}</p>
                        ))
                    }
                    <i className="bi bi-patch-plus-fill added" onClick={() => Added_Ingredients()}></i>
                </div>
                <div>
                    <label className="label">Nutrition info</label>
                    {
                        nutrition?.map((item, index) => (
                            item.name ?
                                <p className="ingrendients" key={index} onClick={() => Remove_Nutrition(item, index)} > {item.name} / {item.value}</p>
                                :
                                ""
                        ))
                    }
                    <i className="bi bi-plus-circle-fill added" onClick={() => Added_Nutrition()}></i>
                </div>
                <div>
                    <label className="label">Images</label>
                    <input type="file" multiple onChange={handleImages} required />
                </div>


                <input type="submit" value="Add" className="submit" />

            </form>
        </div>
    )
}

export default AddProducts
