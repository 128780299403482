import React, { useEffect, useState } from "react";
import { get_all_contact_request } from "../Configuration/Api";
import Swal from "sweetalert2";

function Request() {

    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState('');
    const [selectedPhone, setSelectedPhone] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');

    const getData = async () => {
        const res = await get_all_contact_request();
        if (res?.success) {
            setData(res?.data)
        } else {
            Swal.fire({
                icon: "error",
                title: "Something went wrong"
            })
        }
    }

    useEffect(() => {
        getData()
    }, [])

    const openMessageModal = (message, phone, city, status) => {
        setSelectedMessage(message);
        setSelectedPhone(phone);
        setSelectedCity(city);
        setSelectedStatus(status);
        setIsModalOpen(true);
    }

    return (
        <div>
            <h3 style={{
                backgroundColor: "pink",
                color: "black",
                textAlign: "center",
                padding: "5px",
                borderRadius: "10px"
            }}>All Users Requests</h3>
            <table border="" cellPadding="10" cellSpacing="0" style={{ width: '100%', textAlign: 'left' }}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Subject</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Request</th>
                        <th>View</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index} className="border">
                            <td>{index + 1}</td>
                            <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                            <td>{item.subject}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.request}</td>
                            <td>
                                <button onClick={() => openMessageModal(item.message, item.phone, item.city, item.status)} className="view-button">
                                    View
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h4>Message Details</h4>
                        <p><strong>Message:</strong> {selectedMessage}</p>
                        <p><strong>Phone:</strong> {selectedPhone}</p>
                        <p><strong>City:</strong> {selectedCity}</p>
                        <p><strong>Status:</strong> {selectedStatus}</p>
                        <button onClick={() => setIsModalOpen(false)}>Close</button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Request