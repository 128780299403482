import React from 'react'
import "./Navbar.scss"
function Navbar({ view, change }) {


    return (
        <div>
            <ul className="nav flex-column ">
                <li className={`nav-item mt-3`} onClick={() => change("Home")}>
                    <p className={view == "Home" ? "active_tab" : "tab"}>Home</p>
                </li>
                <li className={`nav-item  mt-3 `} onClick={() => change("All_Orders")}>
                    <p className={view == "All_Orders" ? "active_tab" : "tab"}>Orders</p>
                </li>
                <li className={`nav-item  mt-3 `} onClick={() => change("Request")}>
                    <p className={view == "Request" ? "active_tab" : "tab"}>Requests</p>
                </li>
                <li className={`nav-item  mt-3 `} onClick={() => change("All_Products")}>
                    <p className={view == "All_Products" ? "active_tab" : "tab"}>All Products</p>
                </li>
                <li className={`nav-item  mt-3 `} onClick={() => change("Add_Products")}>
                    <p className={view == "Add_Products" ? "active_tab" : "tab"}>Add New Product</p>
                </li>

                <li className={`nav-item  mt-3 `} onClick={() => change("All_Categories")}>
                    <p className={view == "All_Categories" ? "active_tab" : "tab"}>All Categories</p>
                </li>
                <li className={`nav-item  mt-3 `} onClick={() => change("Add_Categories")}>
                    <p className={view == "Add_Categories" ? "active_tab" : "tab"}>Add Category</p>
                </li>

                  {/* <li className="nav-item  mt-3 mb-3" >
                    <select className={view === "All_Products" || "Add_Products" ? "border p-2 w-100" : "w-100"} onClick={(e) => change(e.target.value)}>
                        <option value="All_Products" >Products</option>
                        <option value="All_Products"  >All Products</option>
                        <option value="Add_Products">Add New Product</option>
                    </select>
                </li> */}

                {/* <li className="nav-item  mt-3 mb-3" >
                    <select className={view === "All_Products" || "Add_Products" ? "border p-2 w-100" : "w-100"} onClick={(e) => change(e.target.value)}>
                        <option value="All_Categories" key={"All_Categories"}>All Categories</option>
                        <option value="Add_Categories" key={"Add_Categories"}>Add Category</option>
                    </select>
                </li> */}

                <li className={`nav-item  mt-3 `} onClick={() => change("All_Subscribe")}>
                    <p className={view == "All_Subscribe" ? "active_tab" : "tab"}>All Subscribers</p>
                </li>
            </ul>
        </div>
    )
}

export default Navbar
